<template>
  <div class="product-detail">
    <div class="currentTitle flex-row jus-center ali-center">
      <div class="title">
        <span class="span1">您的位置：</span>
        <span class="span2">产品中心</span>
        <span class="span3">&gt;</span>
        <span class="span4">智慧健康综合应用系统</span>
      </div>
    </div>
    <div class="box1Div flex jus-center">
      <div>
        <div class="row1">
          <div class="bd4 flex-row">
            <div class="outer1 flex-col">
              <div class="layer2 flex-col"></div>
              <div class="layer3 flex-col">
                <div class="mod1 flex-col"></div>
              </div>
              <div class="layer4 flex-col">
                <div class="outer2 flex-col"></div>
              </div>
              <div class="layer5 flex-col"></div>
            </div>
            <div class="outer3 flex-col">
              <div class="layer6 flex-col"></div>
            </div>
            <div class="outer4 flex-col">
              <span class="txt3">产品产品产品产品产品产品产品</span>
              <span class="infoBox1">
                一产品一产品一产品一产品一产品一产品一产品一产品一产品一产品一产品一
                <br />
                产产品一产品一产品一产品一产品一产品一产品一；
              </span>
              <div class="layer7 flex-col"></div>
              <div class="layer8 flex-row">
                <div class="bd5 flex-col">
                  <span class="txt4">上一篇：产品产品</span>
                </div>
                <div class="bd6 flex-col">
                  <span class="txt5">上一篇：产品产品产品产品</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row2">
          <div class="title">软件开发</div>
          <span class="paragraph1">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产
            <br />
            品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品
          </span>
          <span class="paragraph1">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产
            <br />
            品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品
          </span>
          <span class="paragraph1">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产
            <br />
            品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品产品
          </span>
        </div>
      </div>
    </div>
    <div class="box2Div">
      <div>
        <div class="title">热门推荐</div>
        <div class="bd9 flex-row">
          <div class="section4 flex-row">
            <div
              class="main2 flex-col"
              v-for="(item, index) in loopData0"
              :key="index"
            >
              <div class="wrap1 flex-col">
                <div class="box1 flex-col">
                  <img
                    class="img3"
                    referrerpolicy="no-referrer"
                    :src="item.lanhuimage0"
                  />
                </div>
                <span class="info2" v-html="item.lanhutext0"></span>
                <div class="box2 flex-col"></div>
                <span class="info3" v-html="item.lanhutext1"></span>
                <div class="box3 flex-col">
                  <div class="wrap2 flex-row">
                    <span class="info4">MORE</span>
                    <img
                      class="icon2"
                      referrerpolicy="no-referrer"
                      src="@/assets/product-display/icon1.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductDetail",
  data() {
    return {
      loopData0: [
        {
          lanhuimage0: require('@/assets/project-case/caseImg.png'),
          lanhutext0: "智慧健康综合应用系统",
          lanhutext1: "智慧健康综合应用系统智慧健康综合应用系统智慧<br/>健康综合应用系统智慧健康综合应用系统智慧…",
        },
        {
          lanhuimage0: require('@/assets/project-case/caseImg.png'),
          lanhutext0: "终端设备体验系统",
          lanhutext1: "智慧健康综合应用系统智慧健康综合应用系统智慧<br/>健康综合应用系统智慧健康综合应用系统智慧…",
        },
        {
          lanhuimage0: require('@/assets/project-case/caseImg.png'),
          lanhutext0: "线上健康小程序",
          lanhutext1: "智慧健康综合应用系统智慧健康综合应用系统智慧<br/>健康综合应用系统智慧健康综合应用系统智慧…",
        },
      ],
      constants: {},
    };
  },
};
</script>

<style lang="stylus" scoped>
.currentTitle {
  height: 90px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .title {
    width: 1200px;
    text-align: left;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 18px;

    .span1 {
      color: #333333;
    }
  }
}

.box1Div > div {
  width: 1200px;
  padding-top: 35px;
  padding-bottom: 35px;
  margin: 0 auto;
}

.box2Div {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.box2Div > div {
  width: 1200px;
  padding-bottom: 300px;
  margin: 0 auto;

  .title {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.25);
    line-height: 110px;
    height: 110px;
    text-align: left;
  }
}

.bd9 {
  z-index: auto;
  width: 1530px;
  height: 499px;
  justify-content: space-between;

  .section4 {
    z-index: auto;
    width: 1200px;
    height: 499px;
    justify-content: space-between;

    .main2 {
      z-index: 125;
      height: 500px;
      background-color: rgba(242, 249, 255, 1);
      margin-right: 15px;
      width: 390px;
      justify-content: flex-start;

      .wrap1 {
        z-index: auto;
        width: 390px;
        height: 476px;

        .box1 {
          z-index: 127;
          height: 240px;
          background-color: rgba(194, 213, 230, 1);
          width: 390px;

          .img3 {
            z-index: 128;
            width: 390px;
            height: 240px;
          }
        }

        .info2 {
          z-index: 129;
          width: 240px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 0.85);
          font-size: 24px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 33px;
          text-align: left;
          align-self: center;
          margin-top: 31px;
        }

        .box2 {
          z-index: 138;
          width: 350px;
          height: 1px;
          background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a)
          0px -1px no-repeat;
          align-self: center;
          margin-top: 15px;
        }

        .info3 {
          z-index: 137;
          width: 308px;
          height: 60px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(109, 114, 120, 1);
          font-size: 14px;
          line-height: 30px;
          text-align: left;
          align-self: center;
          margin-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .box3 {
          z-index: 131;
          height: 37px;
          border-radius: 2px;
          border-width: 1px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background-color: rgba(242, 249, 255, 1);
          align-self: center;
          margin-top: 49px;
          width: 140px;
          justify-content: center;
          align-items: center;

          .wrap2 {
            z-index: auto;
            width: 52px;
            height: 17px;
            justify-content: space-between;

            .info4 {
              z-index: 133;
              width: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 0.5);
              font-size: 12px;
              white-space: nowrap;
              line-height: 17px;
              text-align: left;
            }

            .icon2 {
              z-index: 134;
              width: 13px;
              height: 13px;
              background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
              100% no-repeat;
              margin-top: 2px;
            }
          }
        }
      }
    }
  }
}

.section4 {
  z-index: auto;
  width: 1200px;
  height: 499px;
  justify-content: space-between;

  .main2 {
    z-index: 125;
    height: 500px;
    background-color: rgba(242, 249, 255, 1);
    margin-right: 15px;
    width: 390px;
    justify-content: flex-start;

    .wrap1 {
      z-index: auto;
      width: 390px;
      height: 476px;

      .box1 {
        z-index: 127;
        height: 240px;
        background-color: rgba(194, 213, 230, 1);
        width: 390px;

        .img3 {
          z-index: 128;
          width: 390px;
          height: 240px;
        }
      }

      .info2 {
        z-index: 129;
        width: 240px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.85);
        font-size: 24px;
        font-family: PingFangSC-Semibold;
        white-space: nowrap;
        line-height: 33px;
        text-align: left;
        align-self: center;
        margin-top: 31px;
      }

      .box2 {
        z-index: 138;
        width: 350px;
        height: 1px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a)
        0px -1px no-repeat;
        align-self: center;
        margin-top: 15px;
      }

      .info3 {
        z-index: 137;
        width: 308px;
        height: 60px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(109, 114, 120, 1);
        font-size: 14px;
        line-height: 30px;
        text-align: left;
        align-self: center;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .box3 {
        z-index: 131;
        height: 37px;
        border-radius: 2px;
        border-width: 1px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: rgba(242, 249, 255, 1);
        align-self: center;
        margin-top: 49px;
        width: 140px;
        justify-content: center;
        align-items: center;

        .wrap2 {
          z-index: auto;
          width: 52px;
          height: 17px;
          justify-content: space-between;

          .info4 {
            z-index: 133;
            width: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
            white-space: nowrap;
            line-height: 17px;
            text-align: left;
          }

          .icon2 {
            z-index: 134;
            width: 13px;
            height: 13px;
            background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
            100% no-repeat;
            margin-top: 2px;
          }
        }
      }
    }
  }
}

.main2 {
  z-index: 125;
  height: 500px;
  background-color: rgba(242, 249, 255, 1);
  margin-right: 15px;
  width: 390px;
  justify-content: flex-start;

  .wrap1 {
    z-index: auto;
    width: 390px;
    height: 476px;

    .box1 {
      z-index: 127;
      height: 240px;
      background-color: rgba(194, 213, 230, 1);
      width: 390px;

      .img3 {
        z-index: 128;
        width: 390px;
        height: 240px;
      }
    }

    .info2 {
      z-index: 129;
      width: 240px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.85);
      font-size: 24px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 33px;
      text-align: left;
      align-self: center;
      margin-top: 31px;
    }

    .box2 {
      z-index: 138;
      width: 350px;
      height: 1px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a)
      0px -1px no-repeat;
      align-self: center;
      margin-top: 15px;
    }

    .info3 {
      z-index: 137;
      width: 308px;
      height: 60px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(109, 114, 120, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      align-self: center;
      margin-top: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .box3 {
      z-index: 131;
      height: 37px;
      border-radius: 2px;
      border-width: 1px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: rgba(242, 249, 255, 1);
      align-self: center;
      margin-top: 49px;
      width: 140px;
      justify-content: center;
      align-items: center;

      .wrap2 {
        z-index: auto;
        width: 52px;
        height: 17px;
        justify-content: space-between;

        .info4 {
          z-index: 133;
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          white-space: nowrap;
          line-height: 17px;
          text-align: left;
        }

        .icon2 {
          z-index: 134;
          width: 13px;
          height: 13px;
          background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
          100% no-repeat;
          margin-top: 2px;
        }
      }
    }
  }
}

.wrap1 {
  z-index: auto;
  width: 390px;
  height: 476px;

  .box1 {
    z-index: 127;
    height: 240px;
    background-color: rgba(194, 213, 230, 1);
    width: 390px;

    .img3 {
      z-index: 128;
      width: 390px;
      height: 240px;
    }
  }

  .info2 {
    z-index: 129;
    width: 240px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 33px;
    text-align: left;
    align-self: center;
    margin-top: 31px;
  }

  .box2 {
    z-index: 138;
    width: 350px;
    height: 1px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a)
    0px -1px no-repeat;
    align-self: center;
    margin-top: 15px;
  }

  .info3 {
    z-index: 137;
    width: 308px;
    height: 60px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(109, 114, 120, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    align-self: center;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .box3 {
    z-index: 131;
    height: 37px;
    border-radius: 2px;
    border-width: 1px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(242, 249, 255, 1);
    align-self: center;
    margin-top: 49px;
    width: 140px;
    justify-content: center;
    align-items: center;

    .wrap2 {
      z-index: auto;
      width: 52px;
      height: 17px;
      justify-content: space-between;

      .info4 {
        z-index: 133;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
      }

      .icon2 {
        z-index: 134;
        width: 13px;
        height: 13px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
        100% no-repeat;
        margin-top: 2px;
      }
    }
  }
}

.box1 {
  z-index: 127;
  height: 240px;
  background-color: rgba(194, 213, 230, 1);
  width: 390px;

  .img3 {
    z-index: 128;
    width: 390px;
    height: 240px;
  }
}

.img3 {
  z-index: 128;
  width: 390px;
  height: 240px;
}

.info2 {
  z-index: 129;
  width: 240px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: PingFangSC-Semibold;
  white-space: nowrap;
  line-height: 33px;
  text-align: left;
  align-self: center;
  margin-top: 31px;
}

.box2 {
  z-index: 138;
  width: 350px;
  height: 1px;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a)
  0px -1px no-repeat;
  align-self: center;
  margin-top: 15px;
}

.info3 {
  z-index: 137;
  width: 308px;
  height: 60px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(109, 114, 120, 1);
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  align-self: center;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box3 {
  z-index: 131;
  height: 37px;
  border-radius: 2px;
  border-width: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(242, 249, 255, 1);
  align-self: center;
  margin-top: 49px;
  width: 140px;
  justify-content: center;
  align-items: center;

  .wrap2 {
    z-index: auto;
    width: 52px;
    height: 17px;
    justify-content: space-between;

    .info4 {
      z-index: 133;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
    }

    .icon2 {
      z-index: 134;
      width: 13px;
      height: 13px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
      100% no-repeat;
      margin-top: 2px;
    }
  }
}

.wrap2 {
  z-index: auto;
  width: 52px;
  height: 17px;
  justify-content: space-between;

  .info4 {
    z-index: 133;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
  }

  .icon2 {
    z-index: 134;
    width: 13px;
    height: 13px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
    100% no-repeat;
    margin-top: 2px;
  }
}

.info4 {
  z-index: 133;
  width: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  white-space: nowrap;
  line-height: 17px;
  text-align: left;
}

.icon2 {
  z-index: 134;
  width: 13px;
  height: 13px;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
  100% no-repeat;
  margin-top: 2px;
}

.img4 {
  z-index: 73;
  width: 54px;
  height: 222px;
  margin-top: 50px;
}

.row2 {
  .title {
    background: #fff;
    height: 57px;
    text-align: left;
    position: relative;
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #019FD8;
    line-height: 37px;
    padding-left: 15px;

    &:before {
      content: '';
      display: block;
      width: 4px;
      height: 25px;
      background: #019FD8;
      position: absolute;
      left: 0;
      top: 6px;
    }
  }

  .paragraph1 {
    z-index: 66;
    width: 100%;
    height: 72px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    line-height: 36px;
    text-align: left;
    align-self: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.bd4 {
  z-index: auto;
  width: 1200px;
  height: 309px;

  .outer1 {
    z-index: auto;
    width: 150px;
    height: 309px;

    .layer2 {
      z-index: 119;
      width: 41px;
      height: 40px;
      background: url('../assets/product-display/arrow-top.png') 0px 0px no-repeat;
      align-self: center;
    }

    .layer4 {
      z-index: 61;
      position: relative;
      width: 150px;
      height: 95px;
      overflow: hidden;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng54bfa0cd2cb2b8c94f4c27a5d76588861b7103f465700ca9cb9a4bf22d15caf0'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng54bfa0cd2cb2b8c94f4c27a5d76588861b7103f465700ca9cb9a4bf22d15caf0)
      0px -1px no-repeat;
      margin-top: 30px;

      .outer2 {
        z-index: 62;
        position: absolute;
        left: -4px;
        top: 0;
        width: 158px;
        height: 95px;
        background: url('../assets/product-display/product1.png') no-repeat;
        background-size: 100% 100%;
        opacity: .5;
      }
    }

    .layer5 {
      z-index: 116;
      width: 41px;
      height: 40px;
      background: url('../assets/product-display/arrow-bottom.png') 0px 0px no-repeat;
      align-self: center;
      margin-top: 4px;
    }
  }

  .outer3 {
    z-index: 55;
    position: relative;
    width: 460px;
    height: 292px;
    overflow: hidden;
    margin: 9px 0 0 30px;

    .layer6 {
      z-index: 56;
      position: absolute;
      left: -6px;
      top: 0;
      width: 474px;
      height: 292px;
      background: url('../assets/product-display/product2.png') 7px 0px no-repeat;
    }
  }

  .outer4 {
    z-index: auto;
    width: 500px;
    height: 208px;
    margin: 29px 0 0 60px;

    .txt3 {
      z-index: 63;
      width: 308px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.85);
      font-size: 22px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 30px;
      text-align: left;
      align-self: flex-start;
    }

    .infoBox1 {
      z-index: 64;
      width: 476px;
      height: 60px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(109, 114, 120, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      align-self: flex-start;
      margin-top: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .layer7 {
      z-index: 46;
      width: 500px;
      height: 1px;
      border-width: 1px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: 30px;
    }

    .layer8 {
      z-index: auto;
      width: 500px;
      height: 37px;
      margin-top: 35px;
      justify-content: space-between;

      .bd5 {
        z-index: 111;
        height: 37px;
        border-radius: 2px;
        border-width: 1px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: rgba(242, 249, 255, 0);
        width: 130px;
        justify-content: center;
        align-items: center;

        .txt4 {
          z-index: 112;
          width: 96px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          white-space: nowrap;
          line-height: 17px;
          text-align: left;
        }
      }

      .bd6 {
        z-index: 114;
        height: 37px;
        border-radius: 2px;
        border-width: 1px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: rgba(242, 249, 255, 0);
        width: 174px;
        justify-content: center;
        align-items: center;

        .txt5 {
          z-index: 115;
          width: 144px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          white-space: nowrap;
          line-height: 17px;
          text-align: left;
        }
      }
    }
  }
}

.outer1 {
  z-index: auto;
  width: 150px;
  height: 309px;

  .layer4 {
    z-index: 61;
    position: relative;
    width: 150px;
    height: 95px;
    overflow: hidden;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng54bfa0cd2cb2b8c94f4c27a5d76588861b7103f465700ca9cb9a4bf22d15caf0'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng54bfa0cd2cb2b8c94f4c27a5d76588861b7103f465700ca9cb9a4bf22d15caf0)
    0px -1px no-repeat;
    margin-top: 30px;

    .outer2 {
      z-index: 62;
      position: absolute;
      left: -4px;
      top: 0;
      width: 158px;
      height: 95px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb7f6263bcd6ace9d8a3fc925f343bbff873b8a8323dcf36a3f5e7f841d7e7fa1'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb7f6263bcd6ace9d8a3fc925f343bbff873b8a8323dcf36a3f5e7f841d7e7fa1)
      5px -1px no-repeat;
    }
  }
}

.layer3 {
  z-index: 58;
  position: relative;
  width: 150px;
  height: 95px;
  overflow: hidden;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8445f291337ac28885e2b94c2d16468c3e08a84642ed8d4776e56e6ecbd73b5c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8445f291337ac28885e2b94c2d16468c3e08a84642ed8d4776e56e6ecbd73b5c)
  100% no-repeat;
  margin-top: 5px;

  .mod1 {
    z-index: 59;
    position: absolute;
    left: -4px;
    top: 0;
    width: 158px;
    height: 95px;
    background: url('../assets/product-display/product1.png') no-repeat;
    background-size: 100% 100%;
  }
}

.layer4 {
  z-index: 61;
  position: relative;
  width: 150px;
  height: 95px;
  overflow: hidden;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng54bfa0cd2cb2b8c94f4c27a5d76588861b7103f465700ca9cb9a4bf22d15caf0'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng54bfa0cd2cb2b8c94f4c27a5d76588861b7103f465700ca9cb9a4bf22d15caf0)
  0px -1px no-repeat;
  margin-top: 30px;

  .outer2 {
    z-index: 62;
    position: absolute;
    left: -4px;
    top: 0;
    width: 158px;
    height: 95px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb7f6263bcd6ace9d8a3fc925f343bbff873b8a8323dcf36a3f5e7f841d7e7fa1'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb7f6263bcd6ace9d8a3fc925f343bbff873b8a8323dcf36a3f5e7f841d7e7fa1)
    5px -1px no-repeat;
  }
}

.outer2 {
  z-index: 62;
  position: absolute;
  left: -4px;
  top: 0;
  width: 158px;
  height: 95px;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb7f6263bcd6ace9d8a3fc925f343bbff873b8a8323dcf36a3f5e7f841d7e7fa1'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb7f6263bcd6ace9d8a3fc925f343bbff873b8a8323dcf36a3f5e7f841d7e7fa1)
  5px -1px no-repeat;
}

.outer4 {
  z-index: auto;
  width: 500px;
  height: 208px;
  margin: 29px 0 0 60px;

  .txt3 {
    z-index: 63;
    width: 308px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 22px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    align-self: flex-start;
  }

  .infoBox1 {
    z-index: 64;
    width: 476px;
    height: 60px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(109, 114, 120, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    align-self: flex-start;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .layer7 {
    z-index: 46;
    width: 500px;
    height: 1px;
    border-width: 1px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 30px;
  }

  .layer8 {
    z-index: auto;
    width: 500px;
    height: 37px;
    margin-top: 35px;
    justify-content: space-between;

    .bd5 {
      z-index: 111;
      height: 37px;
      border-radius: 2px;
      border-width: 1px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: rgba(242, 249, 255, 0);
      width: 130px;
      justify-content: center;
      align-items: center;

      .txt4 {
        z-index: 112;
        width: 96px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
      }
    }

    .bd6 {
      z-index: 114;
      height: 37px;
      border-radius: 2px;
      border-width: 1px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: rgba(242, 249, 255, 0);
      width: 174px;
      justify-content: center;
      align-items: center;

      .txt5 {
        z-index: 115;
        width: 144px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
      }
    }
  }
}

.txt3 {
  z-index: 63;
  width: 308px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.85);
  font-size: 22px;
  font-family: PingFangSC-Semibold;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  align-self: flex-start;
}

.infoBox1 {
  z-index: 64;
  width: 476px;
  height: 60px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(109, 114, 120, 1);
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  align-self: flex-start;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layer7 {
  z-index: 46;
  width: 500px;
  height: 1px;
  border-width: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.layer8 {
  z-index: auto;
  width: 500px;
  height: 37px;
  margin-top: 35px;
  justify-content: space-between;

  .bd5 {
    z-index: 111;
    height: 37px;
    border-radius: 2px;
    border-width: 1px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(242, 249, 255, 0);
    width: 130px;
    justify-content: center;
    align-items: center;

    .txt4 {
      z-index: 112;
      width: 96px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
    }
  }

  .bd6 {
    z-index: 114;
    height: 37px;
    border-radius: 2px;
    border-width: 1px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(242, 249, 255, 0);
    width: 174px;
    justify-content: center;
    align-items: center;

    .txt5 {
      z-index: 115;
      width: 144px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
    }
  }
}

.bd5 {
  z-index: 111;
  height: 37px;
  border-radius: 2px;
  border-width: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(242, 249, 255, 0);
  width: 130px;
  justify-content: center;
  align-items: center;

  .txt4 {
    z-index: 112;
    width: 96px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
  }
}

.txt4 {
  z-index: 112;
  width: 96px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  white-space: nowrap;
  line-height: 17px;
  text-align: left;
}

.bd6 {
  z-index: 114;
  height: 37px;
  border-radius: 2px;
  border-width: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(242, 249, 255, 0);
  width: 174px;
  justify-content: center;
  align-items: center;

  .txt5 {
    z-index: 115;
    width: 144px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
  }
}

.txt5 {
  z-index: 115;
  width: 144px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  white-space: nowrap;
  line-height: 17px;
  text-align: left;
}
</style>
